import VCB from './bill/VCB'
import {
  convertDateToString2,
  genZipcode,
  getHours,
  dataBank,
  dataBank2
} from '../../utils/utils'
import { useTranslation } from 'react-i18next'
import { useRef, useState } from 'react'

import * as htmlToImage from 'html-to-image'
import TPbank from './bill/TPbank'
import MB from './bill/MB'
import Vietin from './bill/Vietin'
import TCB from './bill/TCB'
import ACB from './bill/ACB'
import BIDV from './bill/BIDV'
import Agribank from './bill/Agribank'
import VPBank from './bill/VPBank'
import Sacombank from './bill/Sacombank'
import MSB from './bill/MSB'
import OCB from './bill/OCB'

const FormInfo = () => {
  useTranslation()
  const domEl = useRef(null)


  const [typeBank, SetTypeBank] = useState('1')
  const [typeFile, SetTypeFile] = useState('1')
  const [money, SetMoney] = useState(0)
  const [money_text, SetMoneyText] = useState('')
  const [name_account, SetNameAccount] = useState('')
  const [number_account, SetNumberAccount] = useState('')
  const [time, SetTime] = useState(getHours(new Date()))
  const [date, SetDate] = useState(convertDateToString2(new Date()))
  const [bank, SetBank] = useState('')
  const [trading_code, SetTradingCode] = useState(genZipcode(typeBank))
  const [content, SetContent] = useState('')
  const [number_person_account, SetNumberPersonAccount] = useState('')
  const [name_person_account, SetNamePersonAccount] = useState('')
  const [bank_logo, SetBankLogo] = useState('')


  let option = { width: 340, height: 650 }
  switch (parseInt(typeBank)) {
    case 1:
      if (typeFile === '1') {
        option = { width: 340, height: 626 }
      } else {
        option = { width: 375, height: 812 }
      }
      break;
    case 2:
      option = { width: 340, height: 710 }
      break;
    case 3:
      option = { width: 345, height: 668 }
      break;
    case 4:
      option = { width: 340, height: 725 }
      break;
    case 5:
      option = { width: 340, height: 750 }
      break;
    case 6:
      option = { width: 360, height: 726 }
      break;
    case 7:
      option = { width: 360, height: 765 }
      break;
    case 8:
      option = { width: 375, height: 810 }
      break;
    case 9:
      option = { width: 390, height: 850 }
      break;
    case 10:
      option = { width: 375, height: 810 }
      break;
    case 11:
      option = { width: 375, height: 810 }
      break;
    case 12:
      option = { width: 375, height: 810 }
      break;
    default:
      option = { width: 340, height: 650 }
      break;
  }
  const downloadImage = async () => {
    const portalDiv = document.getElementById('domEl');
    if (!portalDiv) {
      console.error("Element 'domEl' not found.");
      return;
    }
  
    const dataUrl = await htmlToImage.toPng(portalDiv, option);
  
    fetch(dataUrl)
      .then(res => res.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        // Thay vì sử dụng link.click(), mở hình ảnh trong một tab mới
        window.open(url, '_blank');
        URL.revokeObjectURL(url);
      })
      .catch(err => console.error('Failed to download image:', err));
  };
  
  const nameBank = (value: any) => {
    let logo = dataBank.find(function (element) {
      return element.id === value;
    });
    
    let logo1 = dataBank2.find(function (element) {
      return element.id === value;
    });
    if (typeBank === '8' || typeBank === '10' || typeBank === '11') {
      SetBank(logo1?.name ?? "");
    }else {
      SetBank(logo?.name ?? "");
    }
   
  }
  let data: any = {
    money: money,
    money_text: money_text,
    name_account: name_account,
    number_account: number_account,
    time: time,
    date: date,
    bank: bank,
    trading_code: trading_code,
    content: content,
    number_person_account: number_person_account,
    name_person_account: name_person_account,
    bank_logo: bank_logo ?? {},
    type_file: typeFile
  }
  return (
    <div className="invoice">
      <div className="auto_form">
        <form>
          <div className="col-md-6 col-12">
            <div className="mb-1">
              <label className="form-label" htmlFor="money">
                Số tiền
              </label>
              <input
                type="number"
                id="money"
                value={data.money}
                onChange={e => SetMoney(parseInt(e.currentTarget.value))}
                className="form-control"
                placeholder="Nhập số tiền cần chuyển"
                name="money"
              />
            </div>
          </div>
          {(typeBank === '4' || typeBank === '6') && (
            <div>
              <div className="col-md-6 col-12">
                <div className="mb-1">
                  <label className="form-label" htmlFor="money">
                    Số tiền bằng chũ
                  </label>
                  <input
                    type="text"
                    id="money"
                    value={data.money_text}
                    onChange={e => SetMoneyText(e.currentTarget.value)}
                    className="form-control"
                    placeholder="Nhập số tiền bằng chữ"
                    name="money_text"
                  />
                </div>
              </div>

            </div>
          )}
          {(typeBank === '2' || typeBank === '3' || typeBank === '4'|| typeBank === '6' || typeBank === '11'  || typeBank === '10' || typeBank === '12') && (
            <div>
              <div className="col-md-6 col-12">
                <div className="mb-1">
                  <label className="form-label" htmlFor="name-account">
                    Tên người chuyển
                  </label>
                  <input
                    type="text"
                    id="name-account"
                    className="form-control"
                    name="name_person_account"
                    value={name_person_account}
                    onChange={e => SetNamePersonAccount(e.currentTarget.value)}
                    placeholder="Nhập tên người chuyển"
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="mb-1">
                  <label className="form-label" htmlFor="number-account">
                    Số tài khoản người chuyển
                  </label>
                  <input
                    type="number"
                    id="number-account"
                    className="form-control"
                    name="number_person_account"
                    value={number_person_account}
                    onChange={e => SetNumberPersonAccount(e.currentTarget.value)}
                    placeholder="Nhập số tài khoản người chuyển"
                  />
                </div>
              </div>
            </div>
          )}
          <div className="col-md-6 col-12">
            <div className="mb-1">
              <label className="form-label" htmlFor="name-account">
                Tên người nhận
              </label>
              <input
                type="text"
                id="name-account"
                className="form-control"
                name="name_account"
                value={data.name_account || ''}
                onChange={e => SetNameAccount(e.currentTarget.value)}
                placeholder="Nhập tên người nhận"
              />
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="mb-1">
              <label className="form-label" htmlFor="number-account">
                Số tài khoản nhận
              </label>
              <input
                type="number"
                id="number-account"
                className="form-control"
                name="number_account"
                value={data.number_account || ''}
                onChange={e => SetNumberAccount(e.currentTarget.value)}
                placeholder="Nhập số tài khoản người nhận"
              />
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="mb-1">
              <label className="form-label" htmlFor="time">
                Giờ
              </label>
              <input
                type="time"
                id="time"
                className="form-control flatpickr-time"
                name="time"
                placeholder="Nhập giờ"
                value={data.time || ''}
                onChange={e => SetTime(e.currentTarget.value)}
              />
            </div>
          </div>
          <div className="col-md-6 col-12 position-relative">
            <div className="mb-1">
              <label className="form-label" htmlFor="date">
                Ngày
              </label>
              <input
                type="date"
                id="date"
                className="form-control flatpickr-date"
                name="date"
                placeholder="Nhập ngày"
                value={data.date}
                onChange={e => SetDate(e.currentTarget.value)}
              />
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="mb-1">
              <label className="form-label" htmlFor="bank">
                Ngân hàng thụ hưởng
              </label>
              <select
                value={data.bank_logo}
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                onChange={e => { SetBankLogo(e.currentTarget.value), nameBank(e.currentTarget.value) }}
                className="select2 form-select mb-1"
                name="bank"
              >
                <option value="">Chọn ngân hàng</option>
                <option value="183">Vietcombank</option>
                <option value="184">Agribank</option>
                <option value="185">Vietinbank</option>
                <option value="186">MB</option>
                <option value="187">Techcombank</option>
                <option value="188">ABBank</option>
                <option value="189">ACB</option>
                <option value="190">ANZ</option>
                <option value="191">BacAbank</option>
                <option value="192">BaoVietBank</option>
                <option value="193">Cake by VPbank</option>
                <option value="194">DongA Bank</option>
                <option value="195">Eximbank</option>
                <option value="196">HDBank</option>
                <option value="197">HSBC</option>
                <option value="198">Kien Long Bank</option>
                <option value="199">LienVietPostBank</option>
                <option value="200">MSB</option>
                <option value="201">NCB</option>
                <option value="202">Nam Abank</option>
                <option value="203">OCB</option>
                <option value="204">Oceanbank</option>
                <option value="205">PVcomBank</option>
                <option value="206">SCB</option>
                <option value="207">SHB</option>
                <option value="208">Saigonbank</option>
                <option value="209">SeAbank</option>
                <option value="210">Shinhan Bank</option>
                <option value="211">TPbank</option>
                <option value="212">VIB</option>
                <option value="213">VPbank</option>
                <option value="214">Viet Capital Bank</option>
                <option value="215">Wooribank</option>
                <option value="216">BIDV</option>
                <option value="338">Sacombank</option>
                <option value="385">VietABank</option>
              </select>

            </div>
            <input
              type="text"
              className="form-control flatpickr-date"
              name="bank"
              placeholder="Ngân hàng thụ hưởng"
              value={bank}
              onChange={e => SetBank(e.currentTarget.value)}
            />
          </div>
          <div className="col-md-6 col-12">
            <div className="mb-1">
              <label className="form-label" htmlFor="tranding-code">
                Số tham chiếu
                <span
                  className="text-info"
                  title="Số tham chiếu được tạo tự động"
                >
                  <i className="fas fa-info-circle"></i>
                </span>
              </label>
              <input
                type="text"
                id="tranding-code"
                className="form-control"
                name="trading_code"
                placeholder="Nhập số tham chiếu gồm 6 chữ số"
                value={trading_code}
                onChange={e => SetTradingCode(e.currentTarget.value)}
              />
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="mb-1">
              <label className="form-label" htmlFor="content">
                Nội dung giao dịch
              </label>
              <textarea
                id="content"
                className="form-control"
                name="content"
                rows={3}
                maxLength={150}
                placeholder="Nhập nội dung chuyển khoản"
                value={data.content || ''}
                onChange={e => SetContent(e.currentTarget.value)}
              ></textarea>
            </div>
          </div>
        </form>
      </div>
      <div className="auto_bill">
        <div className="col-md-6 col-12 mb-4">
          <div className="mb-1">
            <label className="form-label" htmlFor="bill_bank">
              Loại Bill
            </label>
            <select
              onChange={e => {
                SetTradingCode(genZipcode(e.currentTarget.value))
                SetTypeBank(e.currentTarget.value)
              }}
              className="select2 form-select"
              name="bill_bank"
            >
              <option value="">Chọn ngân hàng</option>
              <option value="1">Vietcombank</option>
              <option value="2">TPbank</option>
              <option value="3">MB</option>
              <option value="4">Vietinbank</option>
              <option value="5">Techcombank</option>
              <option value="6">ACB</option>
              <option value="7">BIDV</option>
              <option value="8">Agribank</option>
              <option value="9">VPBank</option>
              <option value="10">Sacombank</option>
              <option value="11">MSB</option>
              <option value="12">OCB</option>
            </select>
          </div>
        </div>

        {typeBank === '1' ? (
        <div className="col-md-6 col-12 mb-4">
          <div className="mb-1">
            <label className="form-label" htmlFor="type_file">
              Loại File
            </label>
            <select
              onChange={e => {
                SetTypeFile(e.currentTarget.value)
              }}
              className="select2 form-select"
              name="type_file"
            >
              <option value="">Chọn ngân hàng</option>
              <option value="1">Mặc định</option>
              <option value="2">Hình nền</option>
            </select>
          </div>
        </div>) : ''}

        {typeBank === '1' ? (
          <div ref={domEl} id="domEl" className="auto_bill__zen_image">
            <VCB data={data}></VCB>
          </div>
        ) : typeBank === '2' ? (
          <div ref={domEl} id="domEl" className="auto_bill__zen_image auto_bill__zen_tbpank">
            <TPbank data={data}></TPbank>
          </div>
        ) : typeBank === '3' ? (
          <div ref={domEl} id="domEl" className="auto_bill__zen_image auto_bill__zen_MB">
            <MB data={data}></MB>
          </div>
        ) : typeBank === '4' ? (
          <div ref={domEl} id="domEl" className="auto_bill__zen_image">
            <Vietin data={data}></Vietin>
          </div>
        ) : typeBank === '5' ? (
          <div ref={domEl} id="domEl" className="auto_bill__zen_image">
            <TCB data={data}></TCB>
          </div>
        ) : typeBank === '6' ? (
          <div ref={domEl} id="domEl" className="auto_bill__zen_image">
            <ACB data={data}></ACB>
          </div>
        ) : typeBank === '7' ? (
          <div ref={domEl} id="domEl" className="auto_bill__zen_image">
            <BIDV data={data}></BIDV>
          </div>
        ) : typeBank === '8' ? (
          <div ref={domEl} id="domEl" className="auto_bill__zen_image">
            <Agribank data={data}></Agribank>
          </div>
        ) : typeBank === '9' ? (
          <div ref={domEl} id="domEl" className="auto_bill__zen_image">
            <VPBank data={data}></VPBank>

          </div>
        ) : typeBank === '10' ? (
          <div ref={domEl} id="domEl" className="auto_bill__zen_image">
            <Sacombank data={data}></Sacombank>
          </div>
        ) : typeBank === '11' ? (
          <div ref={domEl} id="domEl" className="auto_bill__zen_image">
            <MSB data={data}></MSB>
          </div>
        ) : typeBank === '12' ? (
          <div ref={domEl} id="domEl" className="auto_bill__zen_image">
            <OCB data={data}></OCB>
          </div>
        ) : (
          ''
        )}

        <button className="btn_download" onClick={downloadImage}>
          Download Bill
        </button>
      </div>
    </div>
  )
}

export default FormInfo
